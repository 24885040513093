<template>
  <div class="fluid" id="attendanceReport">
    <div>
      <div class="attendance-list-div">
        <v-card class="px-5 py-3">
          <v-row>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="attendance-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>Daily Attendance Report</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>

                  <div style="width: 20%; margin-right: 10px">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="Start Date"
                      type="date"
                      name="startdate"
                      style="height: 30px"
                      step="1"
                      v-model="start_date"
                    />
                  </div>

                  <div style="width: 20%; margin-right: 10px">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="End Date"
                      type="date"
                      name="enddate"
                      style="height: 30px"
                      step="1"
                      v-model="end_date"
                    />
                  </div>
                  <div style="width: 200px; margin-right: 10px">
                    <v-select
                      :value="2"
                      style="
                        height: 30px;
                        position: relative;
                        top: 3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      :items="dropDownCompany"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      v-model="company"
                    ></v-select>
                  </div>
                  <div style="width: 200px; margin-right: 10px">
                    <v-select
                      :value="0"
                      style="
                        height: 30px;
                        position: relative;
                        top: 3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      :items="[
                        { id: 0, name: 'All Status' },
                        { id: 1, name: 'On Time' },
                        { id: 2, name: 'Late' }
                      ]"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      v-model="attStatus"
                    ></v-select>
                  </div>

                  <v-btn
                    elevation="2"
                    tile
                    large
                    color="black"
                    class="white--text"
                    style="width: 130px; position: relative; left: 15px"
                    @click="searchEnter"
                    >search</v-btn
                  >
                </v-toolbar>
                <v-toolbar
                  class="attendance-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title style="color: transparent"
                    >Report Daily Attendance</v-toolbar-title
                  >
                  <v-divider
                    class="mx-6"
                    inset
                    vertical
                    style="border: 1px solid transparent"
                  ></v-divider>
                  <div style="border: 1px solid black; width: 200px">
                    <regenerate-report />
                  </div>
                  <div v-if="isAdmin" style="width: 150px; margin-left: 20px">
                    <v-btn
                      elevation="2"
                      tile
                      large
                      color="green"
                      class="white--text"
                      @click="exportRecap"
                      >Export Recapitulation
                      <v-icon small style="margin-left: 5px"
                        >mdi-download</v-icon
                      ></v-btn
                    >
                  </div>
                  <div class="attendance-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px"
                    ></v-text-field>
                    <!-- <div
                      style=" padding: 10px; display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        elevation="2"
                        rounded
                        small
                        style="width:50px;font-size:8px;"
                        color="black"
                        class="white--text"
                        @click="searchEnter"
                        >search</v-btn
                      >
                    </div> -->
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="attendance-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                fixed-header
                height="57vh"
                style="cursor: pointer"
                :headers="headers"
                :items="result"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
              >
                <template v-slot:[`item.date`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.date) }}
                  </div>
                </template>
                <template v-slot:[`item.clock_in`]="{ item }">
                  <v-chip :color="getColorStatus(item.status_clock_in)" green>
                    {{ item.clock_in }}
                  </v-chip>
                </template>
                <template v-slot:[`item.clock_out`]="{ item }">
                  <v-chip :color="getColorStatus(item.status_clock_out)" green>
                    {{ item.clock_out }}
                  </v-chip>
                </template>
                <template v-slot:[`item.body_temperature_in`]="{ item }">
                  <v-chip
                    :color="getTemperatureStatus(item.body_temperature_in)"
                    green
                  >
                    {{ item.body_temperature_in }}
                  </v-chip>
                </template>
                <template v-slot:[`item.use_mask_in`]="{ item }">
                  <v-chip :color="getUseMaskStatus(item.use_mask_in)" green>
                    {{ item.use_mask_in }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>

            <v-dialog
              v-model="dialog"
              :width="wWidth > 780 ? '50vw' : '100vw'"
              persistent
            >
              <v-form readonly ref="entryForm" style="position: relative">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Detail Absensi
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Name
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              NIK
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Clock In
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Area
                              <span
                                style="
                                  margin: 0 0 0 10px;
                                  font-size: 8px;
                                  color: green;
                                "
                                >(valid)
                              </span>
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense style="height: 55px" />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Clock Out
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field dense outlined />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Area
                              <span
                                style="
                                  margin: 0 0 0 5px;
                                  font-size: 8px;
                                  color: green;
                                "
                                >(valid)
                              </span>
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense style="height: 55px" />
                          </v-col>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position: relative; top: 20px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Koordinat
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field outlined dense style="height: 40px" />
                          </v-col>
                          <a href="/" @click.prevent style="font-size: 12px"
                            >Buka di google map</a
                          >
                        </div>
                      </v-col>
                      <!-- <v-col
                        cols="12"
                        md="6"
                        style="padding: 0; position:relative; top:20px; margin:auto; text-align:center;"
                      >
                        <a href="/" @click.prevent style="font-size:12px;">
                          Lihat foto bukti
                        </a>
                      </v-col> -->
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="dialog = !dialog"
                      :loading="loading"
                    >
                      Batal
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      type="submit"
                      text
                      outlined
                      :loading="loading"
                    >
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-row>
        </v-card>
      </div>
    </div>
    <download-csv
      v-if="recapitulationAttendances"
      style="font-size: 0; width: 0; height: 0; overflow: hidden"
      id="exportRecap"
      :data="recapitulationAttendances"
      worksheet="My Worksheet"
      name="ReportRecapitulationAttendance.csv"
    >
    </download-csv>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'attendancereport',
  components: {
    RegenerateReport: () => import('./RegenerateAttendance.vue')
  },
  data: () => ({
    wWidth: window.innerWidth,
    dialog: false,
    eSanqua: buildType.apiURL('esanqua'),
    attendance: buildType.apiURL('attendance'),
    isAdmin: true,
    company: 0,
    attStatus: 0,
    paramAPI: {
      keyword: ''
    },
    headers: [
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Att. Date',
        value: 'date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Clock In',
        value: 'clock_in',
        align: 'left',
        sortable: false
      },
      {
        text: 'Temperature',
        value: 'body_temperature_in',
        align: 'left',
        sortable: false
      },
      {
        text: 'Mask',
        value: 'use_mask_in',
        align: 'left',
        sortable: false
      },
      {
        text: 'Clock Out',
        value: 'clock_out',
        align: 'left',
        sortable: false
      },
      {
        text: 'Working Hours',
        value: 'working_hours',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    recapitulationAttendances: [],
    excel_export_fields: {
      NIK: 'nik',
      Name: 'employee_name',
      'Attendance Date': 'date',
      Company: 'company_name',
      'Clock In': 'clock_in',
      Temperature: 'body_temperature_in',
      Mask: 'use_mask_in',
      'Clock Out': 'clock_out',
      'Working Hours': 'working_hours'
    },

    excel_export_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8'
        }
      ]
    ],
    start_date: '',
    end_date: '',
    dropDownCompany: []
  }),
  // computed: {
  //   dropDownCompany
  // },
  created() {
    const d = new Date()
    d.setDate(d.getDate() - 1)
    const n = d.toLocaleDateString('id').split('/').reverse().join('-')
    console.log(n)
    this.start_date = n
    this.end_date = n
    console.log(this.getUserProfile.employee)
    this.company = parseInt(this.getUserProfile.employee.company.id)
    this.dropdownCompany()
    this.initDataTable()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters(['getIsLoading', 'getUserProfile'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setIsLoading']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    dropdownCompany() {
      const application = this.getUserProfile.level
      console.log(application)
      const result = application.find(({ id }) => id === '1' || id === '6')
      if (result !== undefined) {
        axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then((res) => {
            console.log(res)
            this.dropDownCompany = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.dropDownCompany = [
          {
            id: this.getUserProfile.sanqua_company_id,
            name: this.getUserProfile.sanqua_company_name
          }
        ]
      }
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {
      this.result = []
      axios
        .get(
          `${this.attendance}report/attendance/daily_attendance?start_date=${this.start_date}&end_date=${this.end_date}&keyword=${this.paramAPI.keyword}&company_id=${this.company}&department_id=`
        )
        .then((res) => {
          console.log(res)
          this.result = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    exportRecap() {
      axios
        .get(
          `${this.attendance}report/attendance/generateToExcel?start_date=${this.start_date}&end_date=${this.end_date}&company_id=${this.company}&keyword=${this.paramAPI.keyword}`
        )
        .then((res) => {
          console.log(res.data)
          this.recapitulationAttendances = []
          if (res.data.status_code === '00') {
            this.recapitulationAttendances = res.data.formed_data

            setTimeout(() => {
              document.getElementById('exportRecap').click()
            }, 500)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addAttendance() {
      //   this.initDropdown()
      setTimeout(() => {
        // document.getElementById('archiveAdd').click()
      }, 500)
    },
    importArchive() {
      // setTimeout(() => {
      //   document.getElementById('archiveImport').click()
      // }, 500)
    },
    rowClick(pItem) {
      // this.form.act = 'update'
      // this.form.id = pItem.id
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    convertDate(raw) {
      console.log(raw)
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        return day + '-' + month + '-' + year
      }
    },

    getColorStatus(pStatus) {
      if (pStatus === 'on_time' || pStatus === 'go_after_time') {
        return ''
      } else if (pStatus === 'late' || pStatus === 'go_before_time') {
        return 'red'
      }
    },

    getTemperatureStatus(pTemperature) {
      // if (pType === 'in') {
      if (pTemperature > 37.3) {
        return 'red'
      } else {
        return ''
      }
      // }
    },

    getUseMaskStatus(pMask) {
      // if (pType === 'in') {
      if (pMask === 'YES') {
        return ''
      } else if (pMask === 'NO') {
        return 'red'
      }
      // }
    }
  }
}
</script>

<style lang="scss">
#attendanceReport {
  margin-bottom: 10px;
  .attendance-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .attendance-list-toolbar-1 {
      .attendance-list-toolbar-div {
        width: 30%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .attendance-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  #attendanceReport {
    .attendance-list-div {
      .attendance-list-toolbar-1 {
        .attendance-list-toolbar-div {
          display: none;
        }
      }
      .attendance-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
